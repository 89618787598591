body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content-menu .scroll-to-link {
	font-size: 12px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.p-content-code {
	background-color:#000;
	color:#fff;
	padding-bottom: 20px !important;
	width: 100%;
}
.p-content-code h5 {
    margin: 0;
    padding: 0;
    text-transform: initial;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 10px;
}
.p-content-code code {
    background-color: unset;
}

.method {
	font-size: 0.929em;
	line-height: 20px;
	color: rgb(255, 255, 255);
	padding: 3px 8px;
	text-transform: uppercase;
	font-family: Montserrat, sans-serif;
	margin: 0px 5px 0px 0px;
	border-radius: 4px;
}
.method-post {
	background-color: rgb(24, 111, 175);
}
.method-get {
	background-color: rgb(47, 129, 50);
}